:root {
  --default-input-width: 325px;
  --larger-input-width: 375px;
  --sort-code-input-width: 150px;
  --account-number-input-width: 180px;
  --title-input-width: 130px;
}

/* stylelint-disable-next-line media-feature-range-notation */
@media screen and (max-width: 768px) {
  :root {
    --default-input-width: 100%;
  }
}
.seccl-form-dialog {
  width: 100%;
}

.seccl-dialog-form-body {
  background-color: var(--seccl-color-grey-1);
  padding: 0 var(--seccl-spacing-large);
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  .seccl-form-section-heading {
    font-size: var(--seccl-font-size-large);
    padding: var(--seccl-spacing-large) 0;
  }
}

.seccl-dialog-form-actions {
  background-color: var(--seccl-color-grey-1);
  display: flex;
  flex-direction: row;
  justify-content: space-between !important;
  width: 100%;
}

.seccl-dialog-form-left-actions,
.seccl-dialog-form-right-actions {
  display: flex;
  flex-direction: row;
}

.seccl-dialog-form-actions .seccl-button {
  min-width: 0;
}
.seccl-form-dialog-add-another {
  align-self: flex-start;
}
.validation-toast-content {
  padding: 0;
  margin: 0;
  padding-left: var(--seccl-spacing-large);
}
.seccl-multi-step-form-header-content {
  max-width: var(--default-form-max-width);
}
.trade-illustration-report-header,
.trade-illustration-report-footer {
  text-align: center;
}

.trade-illustration-report-heading {
  font-size: var(--seccl-font-size-xlarge);
}

.trade-illustration-report-header h3 {
  font-weight: var(--seccl-font-weight-regular);
}

.trade-illustration-report-header p {
  margin-bottom: var(--seccl-spacing-large);
}

.trade-illustration-report {
  background-color: var(--seccl-color-white);
  border-radius: var(--seccl-border-radius-large);
  border: 1px solid var(--seccl-color-grey-2);
  overflow: hidden;
}

.trade-illustration-report .seccl-collapsible {
  border: unset;
  border-radius: unset;
  border-bottom: 1px solid var(--seccl-color-grey-2);
}

.trade-illustration-report .seccl-stat-grid {
  min-width: 160px;
  font-size: var(--seccl-font-size-small);
}

.trade-illustration-report .seccl-collapsible-heading-text {
  font-size: var(--seccl-font-size-xlarge);
}

.trade-illustration-report .first-stat-row {
  border-top: none;
}

.trade-illustration-report .seccl-stat-row {
  background-color: var(--seccl-color-white);
}

.trade-illustration-report .seccl-stat-row th {
  font-weight: var(--seccl-font-weight-regular);
}
.seccl-investment-display-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: var(--seccl-input-min-height);
  overflow: hidden;
}

.seccl-form-array-mobile-content .seccl-investment-display-item {
  min-height: unset;
}

.seccl-investment-isin {
  margin-top: var(--seccl-spacing-xxsmall);
  font-size: var(--seccl-font-size-xxsmall);
}

.seccl-investment-kiid {
  margin-top: var(--seccl-spacing-xxsmall);
  margin-bottom: var(--seccl-spacing-medium);
  font-size: var(--seccl-font-size-xxsmall);
}

.seccl-investment-name {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
}

.seccl-new-investments-stat-grid-container {
  margin: var(--seccl-spacing-xxlarge) 0;
  display: flex;
}

.seccl-new-investments-stat-grid {
  max-width: 300px;
}
.seccl-regular-pension-gross-amount {
  height: var(--seccl-input-min-height);
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.seccl-stat-row.sell-investments-subtotal-row {
  padding-left: 0;
  border: none;
}

.sell-investments-subtotal-row tr {
  max-width: 220px;
  align-items: baseline;
}

.sell-investments-subtotal-row th {
  max-width: 220px;
  align-items: baseline;
  font-size: var(--seccl-font-size-medium);
}

.sell-investments-zero-holdings-switch {
  margin-bottom: var(--seccl-spacing-large);
}

.sell-investments-proportional-currency-input {
  max-width: var(--seccl-form-input-max-width);
}
.partial-withdrawal-container {
  display: flex;
  gap: 24px;
}

.partial-withdrawal-hint {
  color: var(--seccl-color-grey-3);
  margin-top: 0;
  margin-bottom: var(--seccl-spacing-xsmall);
  font-size: var(--seccl-font-size-small);
}
.stakeholders-disclaimer {
  font-size: 14px;
  color: var(--seccl-color-grey-5);
}

.create-client-result-dialog {
  min-width: 400px;
}
.edit-book-cost-button-row {
  display: flex;
  justify-content: flex-end;
}

.edit-book-cost-form-array {
  width: 100%;
}

@media screen and (width <= 1000px) {
  .edit-book-cost-form-set-all-button {
    width: 100%;
    margin-bottom: var(--seccl-spacing-large);
  }
}

.seccl-stat-row.edit-book-cost-total-row {
  padding-left: 0;
  border: none;
  background-color: transparent;
}

.edit-book-cost-total-row tr {
  justify-content: flex-start;
  align-items: baseline;
}

.edit-book-cost-total-row th {
  align-items: baseline;
  margin-right: var(--seccl-spacing-medium);
  font-size: var(--seccl-font-size-medium);
}
.seccl-fact-sheet-link {
  display: contents;
}
.seccl-crystallise-terms-and-conditions-dialog {
  background-color: var(--seccl-color-grey-1);
}

.seccl-crystallise-terms-and-conditions-section {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: var(--seccl-spacing-large) 0;
}

.seccl-crystallise-terms-and-conditions {
  flex-grow: 1;
  max-height: 400px;
  background-color: var(--seccl-color-white);
  padding: var(--seccl-spacing-small);
  overflow: auto;
}

.seccl-crystallise-terms-and-conditions ol {
  padding-left: var(--seccl-spacing-medium);
}

.seccl-crystallise-terms-and-conditions li {
  margin: var(--seccl-spacing-xxsmall) 0;
}

.seccl-crystallise-terms-and-conditions-dialog.seccl-dialog-tablet .seccl-crystallise-terms-and-conditions {
  max-height: unset;
  height: unset;
}
.seccl-form-array-payment {
  display: inline-grid;
  margin: var(--seccl-spacing-small) 0;
  gap: var(--seccl-spacing-small);
}

.seccl-form-array-payment .seccl-form-array-title {
  font-weight: var(--seccl-font-weight-bold);
  line-height: unset;
}

.seccl-form-array-payment[data-items='false'] .seccl-form-array {
  margin: 0;
}
.tax-withdrawal-array-text-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: var(--seccl-input-min-height);
}
.seccl-instrument-details-search {
  width: 100%;
  max-width: var(--default-input-width);
}

.seccl-instrument-details-items {
  display: flex;
  flex-direction: column;
}

.seccl-instrument-details-item {
  display: flex;
  align-items: center;
  height: 100%;
}
.seccl-cash-movements-status-banner {
  max-width: unset;
  width: 100%;
  margin-top: var(--seccl-spacing-xxlarge);
}
.assetName {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 325px;
}

.rebalanceContainer {
  max-width: 1300px;
}

.cashAllocation {
  width: clamp(100px, 100%, 530px);
  padding: var(--seccl-spacing-medium) 0;
}

.buySellInputs {
  min-width: var(--title-input-width);
}
.adviser-fees-form-array-text-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: var(--seccl-input-min-height);
}
.seccl-stat-row.investment-summary-row {
  padding-left: 0;
  border: none;
  background-color: transparent;
}
.account-owners-section {
  align-items: center;
}

.account-owners-section .seccl-form-array-input {
  min-height: unset;
}
